const topNavigation = [
    { icon: "flaticon-video-camera", label: "Manual Evaluation", path: 'pendingEvaluation.manual' },
    { icon: "flaticon-check-list", label: "Automatic Evaluation", path: 'pendingEvaluation.automatic' },
    { icon: "flaticon-event", label: "Feedback", path: 'pendingEvaluation.feedback' },
];

const manualTab = [
    { name: 'Practice Test',  path: 'pendingEvaluation.manual.practiceTest' },
    { name: 'Full Mock Test', path: 'pendingEvaluation.manual.fullMockTest' },
    { name: 'Partial Mock Test', path: 'pendingEvaluation.manual.partialMockTest' },
    { name: 'Diagnostic Test', path: 'pendingEvaluation.manual.diagnosticTest' }
];

const automaticTab = [
    { name: 'Practice Test', path:'pendingEvaluation.automatic.practiceTest'},
    { name: 'Full Mock Test', path:'pendingEvaluation.automatic.fullMockTest'},
    { name: 'Partial Mock Test', path:  'pendingEvaluation.automatic.partialMockTest'},
    { name: 'Diagnostic Test', path: 'pendingEvaluation.automatic.diagnosticTest'},
    { name: 'Quiz Test', path: 'pendingEvaluation.automatic.quizTest'}
];

const feedbackTab = [
    { name: 'Practice Test', path:'pendingEvaluation.feedback.practiceTest'},
    { name: 'Full Mock Test', path:'pendingEvaluation.feedback.fullMockTest'},
    { name: 'Partial Mock Test', path:  'pendingEvaluation.feedback.partialMockTest'},
    { name: 'Diagnostic Test', path: 'pendingEvaluation.feedback.diagnosticTest'},
    { name: 'Quiz Test', path: 'pendingEvaluation.feedback.quizTest'}
];

const practiceTests = [];

for (let i = 0; i < 15; i++) {
    practiceTests.push({
        id: i,
        studentName: 'Faysal',
        qId: 'STD1'+i,
        questionIndex: 'Population of Canada',
        section: 'Speaking',
        item: 'OR',
        partOf: 'Practice',
        assignBy: 'Kazi Shahin',
        examDate: '12 March, 2020',
        feedback: 'No',
        addedWithFinalScore: 'No'
    });
}

const automaticEvaluation = [];

for (let i = 0; i < 15; i++) {
    automaticEvaluation.push({
        id: i,
        studentName: 'Faysal',
        qId: 'STD1'+i,
        questionIndex: 'Population of Canada',
        section: 'Reading',
        partOf: 'Practice',
        item: 'MCQCSA',
        examDate: '12 March, 2020',
        g: 15,
        of: 15,
        p: 15,
        s: 15,
        v: 15,
        wd: 15,
        score: 15
    });
}


const feedbackTableData = [];

for (let i = 0; i < 15; i++) {
    feedbackTableData.push({
        id: i,
        qId: 'STD1'+i,
        questionIndex: 'Population of Canada',
        testId: 'T123'+i,
        studentName: 'Faysal',
        partOf: 'Practice',
        preScore: 20+i,
        newScore: 20+i,
        examDate: '12 March, 2020',
        evaluationDate: '12 March, 2020',
    });
}


export default {
    topNavigation,
    manualTab,
    automaticTab,
    feedbackTab,
    practiceTests,
    automaticEvaluation,
    feedbackTableData
};