<template>
  <div>
    <div class="flex justify-center">
      <PageTopNavigation :items="items" item-width="w-32" />
    </div>
    <router-view />
  </div>
</template>

<script>
import PageTopNavigation from "@/components/atom/PageTopNavigation";
import data from "@/data/pending-evaluation/data.js";
export default {
  name: "Pending-Evalution-Index-View",
  components: {
    PageTopNavigation,
  },
  data() {
    return {
      items: data.topNavigation,
    };
  },
};
</script>
