<template>
  <div class="flex justify-center">
    <div class="flex page-top-nav">
      <template v-for="(item, index) in items">
        <router-link tag="div"
          class="p-3 pointer text-center"
          :class="itemWidth"
          :to="{name: item.path}"
          :key="index"
          active-class="active"
          v-if="item.path"
         >
          <template v-if="iconType == 'svg'">
             <span v-html="item.icon"></span>
          </template>
          <i v-else :class="item.icon" class="text-3xl"></i>
          <p class="pt-2 font-bold text-uppercase">{{ item.label }}</p>
        </router-link>
        <!-- Div section will be removed when path value have for all pages -->
        <div
          @click="navigate(index)"
          class="p-3 pointer text-center" 
          :key="index"
          :class="[(currentIndex == index)? 'active' : '', itemWidth]"
          v-else
        >
          <i :class="item.icon" class="text-3xl"></i>
          <p class="pt-2">{{ item.label }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    currentIndex: 0
  }),
  props: {
    items: {
      type: Array,
      required: true
    },
    itemWidth: {
      type: String
    },
    iconType: {
      type: String
    }
  },
  methods: {
      navigate(index) {
          this.currentIndex = index;
      }
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/components/_page-top-navigation.scss";
</style>
